<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import userForm from "./components/Userform";

import {
  fetchSuppliersListApi,
  fetchUserStructuresTypesApi,
  fetchDirectionsListApi,
  fetchDivisionsListApi,
  fetchDepartmentsListApi,
  fetchServicesListApi,
  fetchRolesListApi,
} from "@/api/common";
export default {
  page: {
    title: "Nouvel Utilisateur",
    meta: [{ name: "description", content: appConfig.description }],
  },
  props: {
    currentUser: {},
  },
  components: { Layout, PageHeader, userForm },
  import: { fetchSuppliersListApi, 
            fetchUserStructuresTypesApi,
            fetchDirectionsListApi,
            fetchDivisionsListApi,
            fetchDepartmentsListApi,
            fetchServicesListApi, 
            fetchRolesListApi,
            },
  watch: {
    
  },
  
  data() {
    return {
      title: "Modifier un utilisateur",
      suppliers: [],
      users: [],
      directions: [],
      divisions: [],
      departments: [],
      services: [],
      roles: [],
      userForm: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        supplier: "",
        type: "",
        directSuperior: "",
        role: "",
        structureType: "",
        direction: "",
        division: "",
        department: "",
        service: "",
        password: "",
        id_number: "",
      },
      userStructuresTypes: [],
      types: [
        {
          id: "employee",
          name: "Employé SH",
        },

        {
          id: "supplier_staff",
          name: "Employé Prestataire",
        },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Paramètres",
          href: "/",
        },
        {
          text: "Nouvel Utilisateur",
          active: true,
        },
      ],
    };
  },

  validations: {
    userForm: {
      firstName: { required },
      lastName: { required },
      email: { required },
      username: { required },
      password: { required }
    },
  },

  created() {
    // this.fetchSuppliersList();
    // this.fetchUserStructuresTypes();
    // this.fetchDirectionsList();
    // this.fetchDivisionsList();
    // this.fetchDepartmentsList();
    // this.fetchServicesList();
    // this.fetchRolesList();
    this.fechSingleUserData();
  },

  methods: {
    fetchDirectionsList(){
      fetchDirectionsListApi()
        .then((res) => (this.directions = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchDivisionsList(){
      fetchDivisionsListApi()
        .then((res) => (this.divisions = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchDepartmentsList(){
      fetchDepartmentsListApi()
        .then((res) => (this.departments = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchServicesList(){
      fetchServicesListApi()
        .then((res) => (this.services = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchUserStructuresTypes() {
      fetchUserStructuresTypesApi()
        .then((res) => (this.userStructuresTypes = res.data.original.data))
        .catch(() => {})
        .finally(() => {});
    },
    fetchSuppliersList() {
      fetchSuppliersListApi()
        .then((res) => (this.suppliers = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchRolesList(){
      fetchRolesListApi()
      .then((res) => (this.roles = res.data.original.data))
        .catch(() => {})
        .finally(() => {});
    },
    fechSingleUserData(){
      this.$http
        .post("/system/users/getSingleUserData/"+ this.$route.params.uid)
        .then((res) => {
          var user = res.data.data;
          this.userForm = user;
          console.log(this.userForm);
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      var router = this.$router;
      this.$http
        .post("/system/users/update", this.userForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              router.push({ name: "settings.users.index" });
              break;

            case 500:
              this.$toast.error(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
            this.$toast.error(error.message);
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <user-form :user="userForm"/>

  </Layout>
</template>
